import "./App.css";
import { LandingPage } from "./pages/LandingPage";

const App = () => {
  return (
    <div>
      <LandingPage />
    </div>
  );
};

export default App;
